import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Link from '../../components/link/link';
import SEO from '../../components/seo/seo';

import './404.css';

const NotFound = () => (
  <>
    <SEO title="About the authors" />
    <main className="not-found">
      <div className="not-found__inner">
        <h1>404: not found</h1>
        <StaticImage
          alt="The sockpicker carrying a sock"
          aspectRatio={3535 / 4229}
          className="not-found__image"
          height={300}
          layout="constrained"
          loading="eager"
          objectFit="contain"
          placeholder="tracedSVG"
          src="../../images/sockpicker.png"
        />
        <p>
          Whoops, we can&apos;t find the page you are looking for. Maybe that rascal the Sockpicker took it.
        </p>
        <div className="not-found__navigation">
          <Link
            to="/"
            transition={false}
          >
            Home
          </Link>
        </div>
      </div>
    </main>
  </>
);

export default NotFound;
